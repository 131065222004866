import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Problems that I can solve for you`}</h2>
    <ProjectCard title="Web Development" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
    If you want to be online and grow your sales or even let people from out of your circle know you, I am here for you.
  </ProjectCard>
    <ProjectCard title="UX/UI" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
    Need to present your business in the most modern way and attract as many leads as you can just because your app or your website design is beautiful as much as your client won't leave your online product, I am here for you.
    </ProjectCard>
    <ProjectCard title="Mobile apps" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
    Do you want to take a place in 6.3 billion smartphones across the world, and make your ideas applied to mobile apps, also I am here for you.
    </ProjectCard>
    <ProjectCard title="Digital Creation" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
    Don't think I'm going to let you need anything because also my team can handle all things you may need, from social media to content creation to logo and branding design, I told you, I am here for you.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      