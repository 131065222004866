import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Hi again!, my name is Farouk Alkheame, I am a software engineer with more than 5 years of experience as a Full-stack developer and programming instructor,`}</p>
    </blockquote>
    <p>{`I helped a lot of companies to grow their sales and make their system and their workflow perform better, I and my team have the needed experience to make your business reach the goal that it should reach, so don't worry I am here for you.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      